import React, {Suspense} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {ApolloProvider} from '@apollo/client';
import {HelmetProvider} from 'react-helmet-async';
import {ThemeProvider} from 'styled-components/macro';
import {PageViews} from '@piwikpro/react-piwik-pro';

import theme from './utils/theme';
import ScriptErrorBoundary from './utils/ScriptErrorBoundary';
import DataErrorBoundary from './utils/DataErrorBoundary';
import LoadingBoundary from './utils/LoadingBoundary';
import SessionDataPublisher from './utils/SessionDataPublisher';
import {apolloClient} from './apollo/client';
import Fonts from './fonts/Fonts.js';
import GlobalStyle from './primitives/GlobalStyle';
import Header, {HeaderStickinessProvider} from './layout/Header';
import ErrorDisplay from './layout/ErrorDisplay';
import LoadingDisplay from './layout/LoadingDisplay';
import UserAttentionRequired from './layout/UserAttentionRequired';
import Routes from './Routes';
import WhenIdle from './utils/WhenIdle';
import LogRocket from './utils/LogRocket';
import HandleLogin from './utils/HandleLogin';

const App = () => {
  PageViews.trackPageView();

  return (
    <ScriptErrorBoundary
      display={(error) => <ErrorDisplay errors={[error]} unstyled />}
    >
      <ThemeProvider theme={theme}>
        <>
          <Fonts />
          <GlobalStyle />
          <ScriptErrorBoundary
            display={(error) => <ErrorDisplay errors={[error]} />}
          >
            <Router>
              <ApolloProvider client={apolloClient}>
                <HelmetProvider>
                  <Suspense fallback={<LoadingDisplay />}>
                    <LoadingBoundary display={() => <LoadingDisplay />}>
                      <DataErrorBoundary
                        display={(errors) => <ErrorDisplay errors={errors} />}
                      >
                        {(errorDisplay) => (
                          <React.StrictMode>
                            <HandleLogin>
                              <HeaderStickinessProvider>
                                <Header />
                                <UserAttentionRequired />
                                {errorDisplay}
                                <Routes />
                              </HeaderStickinessProvider>
                            </HandleLogin>
                          </React.StrictMode>
                        )}
                      </DataErrorBoundary>
                    </LoadingBoundary>
                    <SessionDataPublisher />
                    <WhenIdle>
                      <LogRocket />
                    </WhenIdle>
                  </Suspense>
                </HelmetProvider>
              </ApolloProvider>
            </Router>
          </ScriptErrorBoundary>
        </>
      </ThemeProvider>
    </ScriptErrorBoundary>
  );
};

export default App;
